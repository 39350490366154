import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap';
import {ArrowRightCircle} from 'react-bootstrap-icons'
import './Banner.css';
import BannerGif from '../../assets/images/bannerpic.png';

const Banner = () => {
    
  return (
    <section className='banner' id="home">
        <Container className="banner-container">
            <Row className='align-items-center'>
                <Col sm={12} md={6} xl={5}>
                    <h1>One-Stop Shop for All Your Branding Needs</h1>
                    <Image src={BannerGif} alt='Header image' className="img-fluid d-block d-md-none" />
                    <p>At Brand Space&reg;, we shape experiences. Your vision combined with our expertise outlines your business future to what you’ve imagined – and much more.</p>
                    <a href="#services">Get Started <ArrowRightCircle size={25} /></a>
                </Col>
                <Col sm={12} md={6} xl={7}>
                    <Image className='img-fluid d-none d-md-block' src={BannerGif} alt='Header image' />
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Banner;