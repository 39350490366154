import React from 'react'
import './identityformulation.css'
import { Row, Col, Image } from 'react-bootstrap'
import {motion} from 'framer-motion'
import GraphicsPic from '../../../assets/images/firstmockup2.png'
import ProductDesign from '../../../assets/images/productdesign.jpg';
import WebDesign from '../../../assets/images/design.jpg';
import BrandingDesign from '../../../assets/images/branding.jpg';
import DiscoveryIcon from '../../../assets/images/discovery.png';
import IdeationIcon from '../../../assets/images/ideation.png';
import LaunchIcon from '../../../assets/images/launch.png';
import PrototypeIcon from '../../../assets/images/prototype.png';
import ServiceLandingSection from '../../ServiceLandingSections/ServiceLandingSection';
import {ArrowRightCircle} from 'react-bootstrap-icons';


const IdentityFormulation = () => {

const service_usp = "compelling design";
const service_subtitle = "Effective design can boost engagement by 10x."

return (

  <motion.div className='design-container'>
    <ServiceLandingSection service_usp={service_usp} service_subtitle={service_subtitle} />
    
    <div className='design-services-heading'>
      <h2 className='web-dev-approach-heading'>Our design services</h2>
    </div>

      <div className="design-services-grid">
        <Row className="mt-4 design-services-row">
          <Col md={4} sm={12} className='order-md-2 mb-3'>
            <Image src={WebDesign} alt="Image" className='design-services-image web-design-img' />
          </Col>

          <Col md={8} sm={12} className='design-services-desc'>
            <h3>Website Design</h3>
            <p>Websites act as the gateway between your business and potential customers, so we prioritize creating visually appealing, responsive, and user-friendly designs. Our website design process starts with a deep dive into your brand and audience, allowing us to tailor every element to your unique identity. Whether you're looking for a modern corporate site, an e-commerce platform, or a personal blog, our team of skilled designers and developers is here to turn your vision into a captivating online presence that engages visitors and drives results.</p>
          </Col>
        </Row>
      </div>

      <div className="design-services-grid">
        <Row className="design-services-row">
          <Col md={6} sm={12} className='order-md-2 design-services-desc'>
            <h3>Product (UI/UX) Design</h3>
            <p>Whether it's a mobile app, web application, or software platform, we strive to create intuitive, engaging, and delightful experiences for your users. Our UI/UX design process involves in-depth research, wireframing, prototyping, and iterative testing to ensure that every element of your product not only looks stunning but also functions seamlessly, resulting in a digital masterpiece that resonates with your target audience.</p>
          </Col>

          <Col md={6} sm={12} className='mb-3'>
          <Image src={ProductDesign} alt="Image" className='design-services-image' />
          </Col>
        </Row>
      </div>

      <div className="design-services-grid">
        <Row className="mt-4 design-services-row">
          <Col md={5} sm={12} className='order-md-2 mb-3'>
            <Image src={GraphicsPic} alt="Image" className='design-services-image' />
          </Col>

          <Col md={7} sm={12} className='design-services-desc'>
            <h3>Graphic Design</h3>
            <p>With <b>Brand Space</b>, you gain a creative partner dedicated to translating your ideas into captivating visuals. Whether you need a stunning logo, compelling marketing campaigns, or an impressive online presence, our graphic design experts are here to turn your vision into a reality.</p>
            <p>We prioritize consistency so we also tailor our graphics according to social media platforms, and your marketing strategy for social media.</p>
          </Col>
        </Row>
      </div>

      <div className="design-services-grid">
        <Row className="design-services-row">
          <Col md={6} sm={12} className='order-md-2 design-services-desc'>
            <h3>Branding</h3>
            <p>Strong branding is the cornerstone of a successful business. We are dedicated to helping you define and communicate your unique identity to the world. From logo design and color palettes to messaging and market positioning, we create a cohesive and memorable brand that resonates with your target audience and sets you apart in a competitive landscape.</p>
          </Col>

          <Col md={6} sm={12} className='mb-3'>
          <Image src={BrandingDesign} alt="Image" className='design-services-image' />
          </Col>
        </Row>
      </div>

  <div className='design-processes-container'>
    <div className='design-services-heading'>
      <h2 className='design-process-heading'>Our process</h2>
    </div>

    <Row className='mt-5'>
      <Col md={3} sm={12}>
        <div className='design-processes-images'>
          <Image src={DiscoveryIcon} />
        </div>
        <h2 className='design-processes-title'>Discovery</h2>
        <p className='design-processes-desc'>We discuss your business goals and your customers to identify valuable design opportunities.</p>
      </Col>

      <Col md={3} sm={12}>
        <div className='design-processes-images'>
          <Image src={IdeationIcon} />
        </div>
        <h2 className='design-processes-title'>Ideation</h2>
        <p className='design-processes-desc'>We explore creative solutions to address the opportunities identified in the previous step.</p>
      </Col>

      <Col md={3} sm={12}>
        <div className='design-processes-images'>
          <Image src={PrototypeIcon} />
        </div>
        <h2 className='design-processes-title'>Iterate</h2>
        <p className='design-processes-desc'>We leverage clickable prototypes to refine your design, ensuring a consistent vision and user expectations.</p>
      </Col>

      <Col md={3} sm={12}>
        <div className='design-processes-images'>
          <Image src={LaunchIcon} />
        </div>
        <h2 className='design-processes-title'>Launch</h2>
        <p className='design-processes-desc'>We offer a design system, a brand identity, and regular maintenance to assist you in the development process.</p>
      </Col>
    </Row>
  </div>

  <div className='addvalue-container'>
        <div className='addvalue-content'>
          <h1 className='addvalue-title'>Services that <br />
            <span className='addvalue-usp'>add value</span> to your website</h1>

          <Row>
          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card1'>
              <h4 className='addvalue-card-title'>Website Development</h4>
              <h2 className='addvalue-card-question'>Want to take your design a step further?</h2>
              <p className='addvalue-card-desc'>We transform your captivating visual concepts into fully functional, user-friendly websites, ensuring that your online presence not only looks stunning but also delivers exceptional user experiences. Partner with us to bring your design to life on the web and make a lasting impact on your audience.</p>

              <div className='service-cta'>
                <a href="/website">Explore our services for website development <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>

          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card2'>
              <h4 className='addvalue-card-title'>Content Marketing</h4>
              <h2 className='addvalue-card-question'>Do you struggle to create consistent and valuable content?</h2>
              <p className='addvalue-card-desc'>
                Effective content planning lays the foundation for creating valuable and engaging content
                that resonates with your target audience, while strategic content promotion ensures that 
                your carefully crafted content reaches the widest and most receptive audience possible, 
                driving traffic, engagement, and conversions.
              </p>

              <div className='service-cta'>
                <a href="/marketing">More about our content marketing <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>
          </Row>
        </div>

      </div>

  </motion.div>


);

}

export default IdentityFormulation;


