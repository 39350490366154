import {React, useEffect, useState} from 'react'
import './takingitonline.css'
import { Row, Col, Image, Container } from 'react-bootstrap'
import {motion} from 'framer-motion'
import AnalyticsPic from '../../../assets/images/analytics.svg';
import ServiceLandingSection from '../../ServiceLandingSections/ServiceLandingSection';
import sr from 'scrollreveal';
import checkbox from '../../../assets/images/checkbox.svg';
import './TimelineItem.css';
import {ArrowRightCircle} from 'react-bootstrap-icons';


const TakingItOnline = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => {
          setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      // Clean up the event listener when the component is unmounted
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
    const config = {
      duration: 800,
      distance: '150px',
      easing: 'ease-in-out',
    };
    const scrollReveal = sr();

    if (screenWidth < 768) {
      // Apply different animation for mobile screens
      scrollReveal.reveal('.js--fadeInRight', {
        origin: 'right',
        ...config,
      });
    } else {
      // Apply animation for larger screens
      scrollReveal.reveal('.js--fadeInLeft', {
        origin: 'left',
        ...config,
      });

      scrollReveal.reveal('.js--fadeInRight', {
        origin: 'right',
        ...config,
      });
    }

  }, [screenWidth]);

const service_usp = "website development";
const service_subtitle = "Good web design increases conversions by up to 200%."

  return (
    <motion.div className='web-dev-container'>
      <ServiceLandingSection service_usp={service_usp} service_subtitle={service_subtitle} />
      
      <Row className='mb-4 tech-stack-container'>
        <h3>Technologies we use</h3>
        <div className='ticker-wrap'>
          <div className='ticker'>
            <div className="ticker__item">Webflow</div>
            <div className="ticker__item">WordPress</div>
            <div className="ticker__item">Shopify</div>
            <div className="ticker__item">Figma</div>
            <div className="ticker__item">HTML/CSS</div>
            <div className="ticker__item">JavaScript</div>
            <div className="ticker__item">React</div>
            <div className="ticker__item">Python</div>
            <div className="ticker__item">Next.js</div>
            <div className="ticker__item">Django</div>
            <div className="ticker__item">MongoDB</div>
            <div className="ticker__item">SQL</div>
          </div>
        </div>
      </Row>
      
      <div className='web-dev-approach'>
        <h2 className='web-dev-approach-heading'>Our approach</h2>
        <p className='web-dev-approach-description'>We use an iterative process that covers a simple website showcasing your business, to a complex web application solution; with precision, and perfection.</p>
      </div>
    
      <section className="timeline">
        <Container>
          <Row className='align-items-center'>
            <Col sm={12}>
              <div className="timeline-item">
                <div className="timeline-img"></div>
        
                <div className="timeline-content timeline-card js--fadeInLeft">
                  <div className="timeline-img-header discovery-img">
                    <h2>Discovery</h2>
                  </div>
                  <div className="date">STEP 1</div>
                  <div className='timeline-card-content align-items-center'>
                    <ul>
                      <li>
                        <span className="timeline-card-bullet-title">
                          Understanding Your Vision:
                        </span> Our initial consultation is all about you. We want to hear your vision, your business story, and your dreams for this project. It's your chance to share what makes your brand unique and what you hope to achieve.
                      </li>

                      <li>
                        <span className="timeline-card-bullet-title">
                          Personalized Roadmap:
                        </span> Together, we'll map out a custom plan that fits your needs like a glove. We'll dive into the nitty-gritty details – from project scope to timelines, technology choices, and creative strategies. This is where we tailor our expertise to match your goals.
                      </li>

                  </ul>
                    <a className="timeline-btn" href="/contact">Get Started</a>
                  </div>
                </div>
        
              </div> 
            </Col>
          </Row>

          <Row className='align-items-center'>
            <Col sm={12}>
              <div className="timeline-item card-right">
                <div className="timeline-img"></div>
        
                <div className="timeline-content card-right timeline-card js--fadeInRight">
                  <div className="timeline-img-header design-img">
                    <h2>Design & Feedback</h2>
                  </div>
                  <div className="date">STEP 2</div>
                  <div className='timeline-card-content align-items-center'>
                    <ul>
                      <li>
                        <span className="timeline-card-bullet-title">
                          Visual Mockups:
                        </span> Creating visual representations of the project's design, including desktop and mobile versions, to visualize the user interface and layout.
                      </li>

                      <li>
                        <span className="timeline-card-bullet-title">
                          Iterative Design Process:
                        </span> Involving frequent feedback cycles and high-fidelity mockups to refine the user interface, enhance the user experience, and ensure consistency through the development of an expert design system.
                      </li>

                  </ul>
                    <a className="timeline-btn" href="/contact">Get Started</a>
                  </div>
                </div>
        
              </div> 
            </Col>
          </Row>

          <Row className='align-items-center'>
            <Col sm={12}>
              <div className="timeline-item">
                <div className="timeline-img"></div>
        
                <div className="timeline-content timeline-card js--fadeInLeft">
                  <div className="timeline-img-header development-img">
                    <h2>Development</h2>
                  </div>
                  <div className="date">STEP 3</div>
                  <div className='timeline-card-content align-items-center'>
                  <ul>
                      <li>
                        <span className="timeline-card-bullet-title">
                          Expertly Crafted, Responsive Code:
                        </span> We bring your project to life by coding your website from scratch, ensuring it's responsive and interactive. Our team's expertise ensures that your digital presence is not only visually stunning but also fully functional across various platforms and devices.
                      </li>

                      <li>
                        <span className="timeline-card-bullet-title">
                          Enhanced User Experience:
                        </span> We use our SEO strategy to fine-tune your website for search engines, ensuring higher visibility and organic traffic. Our efficient coding practices and captivating animations enhance user engagement and create a stellar online experience.
                      </li>

                  </ul>
                    <a className="timeline-btn mt-4" href="/contact">Get Started</a>
                  </div>
                </div>
        
              </div> 

            </Col>
          </Row>

          <Row className='align-items-center'>
            <Col sm={12}>
              <div className="timeline-item card-right">
                <div className="timeline-img"></div>
        
                <div className="timeline-content card-right timeline-card js--fadeInRight">
                  <div className="timeline-img-header deployment-img">
                    <h2>Go Live</h2>
                  </div>
                  <div className="date">STEP 4</div>
                  <div className='timeline-card-content align-items-center'>
                    <ul>
                      <li>
                        <span className="timeline-card-bullet-title">
                          Effortless Launch:
                        </span> We provide a comprehensive backend demo and quality control to ensure a smooth project launch. Our team evaluates hosting options, delivering seamless go-live support for a reliable and hassle-free experience.
                      </li>

                      <li>
                        <span className="timeline-card-bullet-title">
                          Seamless Web Hosting:
                        </span> Powered by AWS and Fastly, we provide a secure, reliable and scalable solution to host your website globally, ensuring it remains accessible even during traffic spikes.
                      </li>

                  </ul>
                    <a className="timeline-btn" href="/contact">Get Started</a>
                  </div>
                </div>
        
              </div> 
            </Col>
          </Row>
      
        </Container>
      </section>

      <Container className='webdev-sells-container'>
        <Row>
          <Col md={5} sm={12} className='order-md-2'>
            <Image fluid src={AnalyticsPic} />
          </Col>

          <Col className='webdev-sells' md={7} sm={12}>
              <div>
                <h3>Level up your digital growth</h3>
                <p>Cultivate your digital presence through strategic website enhancements that captivate your audience, drive conversions, and cultivate long-term customer relationships.
                  <b> Partners</b> is not a word we take lightly; your success is our success.
                </p>
                <p>This is what our strategies guarantee:</p>
                  <ul className='webdev-sell-points'>
                    <li><Image className='webdev-sell-img' src={checkbox} /> <span className='webdev-sell-title'>Increase brand presence</span>
                      {/* <span className='webdev-sell-description'>Enhance your website's design and content to establish a strong online brand identity that resonates with your target audience</span> */}
                    </li>
                    <li>
                      <Image className='webdev-sell-img' src={checkbox} /> <span className='webdev-sell-title'>Increase customer retention</span>                   
                    </li>
                    <li><Image className='webdev-sell-img' src={checkbox} /> <span className='webdev-sell-title'>In-Depth Social Analytics</span>
                    </li>
                  </ul>
              </div>
          </Col>
        </Row>
      </Container>

      <div className='addvalue-container'>
        <div className='addvalue-content'>
          <h1 className='addvalue-title'>Services that <br />
            <span className='addvalue-usp'>add value</span> to your website</h1>

          <Row>
          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card1'>
              <h4 className='addvalue-card-title'>Graphics Design</h4>
              <h2 className='addvalue-card-question'>Do your social media graphics reflect your brand consistently?</h2>
              <p className='addvalue-card-desc'>From profile pictures to video thumbnails, ad graphics, and much more. Partner with our team of experts to design your social media according to your brand identity.</p>
              <ul className='addvalue-card-list'>
                <li>Social media posts</li>
                <li>Profile pictures</li>
                <li>Ad graphics</li>
              </ul>
              <b>... and much more</b>

              <div className='service-cta'>
                <a href="/design">Explore our services for graphic design <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>

          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card2'>
              <h4 className='addvalue-card-title'>Content Marketing</h4>
              <h2 className='addvalue-card-question'>Do you struggle to create consistent and valuable content?</h2>
              <p className='addvalue-card-desc'>
                Effective content planning lays the foundation for creating valuable and engaging content
                that resonates with your target audience, while strategic content promotion ensures that 
                your carefully crafted content reaches the widest and most receptive audience possible, 
                driving traffic, engagement, and conversions.
              </p>

              <div className='service-cta'>
                <a href="/marketing">More about our content marketing <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>
          </Row>
        </div>

      </div>

    </motion.div>
  )
}

export default TakingItOnline;
