import {React} from 'react'
import { Col, Image } from 'react-bootstrap'
import './servicecard.css'



const ServiceCard = ({title, bullet_points, mainicon, starsicon, location}) => {

  return (

    <Col md={4} className="home-service-card">
    <div className='single-service'>
      <div className='service-content'>

        <div className="service-icon-thumb">
          <Image src={mainicon} width={112} height={112} alt='service_icon' fluid />
        </div>
        <div className="service-circle-icon-thumb rotateme">
          <Image src={starsicon} width={28} height={140} alt='stars_rotating' fluid />
        </div>

        <div className='service-inner'>
          <div className='service-title'>
            <h3>{title}</h3>
            <button className='learnmore-button'>
              <a href={location} style={{ textDecoration: "none", color: "inherit" }}>
                View Details
              </a>
          </button>
          </div>
          <div className='service-description'>
            <ul>
              {bullet_points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Col>


  )
}

export default ServiceCard;
