import { AuthProvider } from './context';
import { Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage/HomePage";
import LandingPage from './pages/LandingPage/LandingPage';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import IdentityFormulation from './components/Service/1IdentityFormulation/IdentityFormulation';
import TakingItOnline from './components/Service/2TakingItOnline/TakingItOnline';
import ProductDevelopment from './components/Service/4ProductDevelopment/ProductDevelopment';
import Marketing from './components/Service/5Marketing/Marketing';
import ContactUs from './pages/ContactUs/ContactUs';
import UserResearch from './components/Service/3NextSteps/Userresearch';



function App() {
  return (
    <AuthProvider>
        <Routes>
          <Route path='/' element={<LandingPage />}>
            <Route index element={<HomePage />} />
            <Route path='home/' element={<HomePage />}/>
            <Route path='design/' element={<IdentityFormulation />}/>
            <Route path='website/' element={<TakingItOnline />}/>
            <Route path='development/' element={<ProductDevelopment />}/>
            <Route path='ux-research/' element={<UserResearch />}/>
            <Route path='marketing/' element={<Marketing />}/>
            <Route path='contact/' element={<ContactUs />}/>
          </Route>
        </Routes>
    </AuthProvider>
  );
}

export default App;
