import React, { useState } from 'react'
import './contactus.css'
import '../HomePage/homepage.css'
import CalendlyComponent from '../../components/CalendlyComponent/CalendlyComponent'
import axios from 'axios';

const ContactUs = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const timestamp = new Date().toLocaleString();

    const data = {
      Name: name,
      Email: email,
      Message: message,
      Timestamp: timestamp
    };

    axios.post("https://sheet.best/api/sheets/c541edda-8c1d-4a0e-916f-15c396c92cb8", data).then((res) => {
      setName('');
      setEmail('');
      setMessage('');
      alert("Thank you for contacting us! We'll be in touch shortly :)")
    })
  };

  return (
    <div className='contactus-container'>
      <div className='contactus-form-headings'>
          <p className='contactus-form-heading'>Contact</p>
          <p className='contactus-form-subheading'>Schedule a call, or leave a message!</p>
      </div>
      <div className='contactus-wrapper'>
        <div className='calendar'>
          <CalendlyComponent />
        </div>
        <div className='contactus-right'>
            <form className='contactus-form' method='POST' onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='name'>Name
                  <input type='text' id='name' name='Name' value={name}
                    placeholder='John Doe' required
                    onChange={e => setName(e.target.value)} />
                </label>
              </div>
              <div className='form-group'>
                <label htmlFor='email'>Email
                  <input type='text' id='email' name='Email' value={email}
                    placeholder='john.doe@gmail.com' required
                    onChange={e => setEmail(e.target.value)} />
                </label>
              </div>
              <div className='form-group'>
                <label htmlFor='message'>Message
                  <textarea id='message' name='Message' value={message}
                    placeholder="Hi,
                  
                    I run a small business and would like to..."
                    required
                    rows={4}
                    onChange={e => setMessage(e.target.value)} />
                </label>
              </div>

              <button className='contactus-submit-btn' type='submit'>Submit</button>
            </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs;


