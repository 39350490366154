import React from 'react'
import {motion} from 'framer-motion'
import ServiceLandingSection from '../../ServiceLandingSections/ServiceLandingSection';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import SEOIcon from '../../../assets/images/seo_icon.png';
import SocialMediaMarketing from '../../../assets/images/socialmediamarketing.png';
import EmailMarketing from '../../../assets/images/emailmarketing.png';
import Branding from '../../../assets/images/branding.png';
import PayPerClick from '../../../assets/images/payperclick.png';
import ContentMarketing from '../../../assets/images/content.png';
import './Marketing.css';

const Marketing = () => {

    const service_usp = "digital marketing";
    const service_subtitle = "The best marketing doesn't feel like marketing."

  return (
    <motion.div className='marketing-container'>
        <ServiceLandingSection service_usp={service_usp} service_subtitle={service_subtitle} />

        <div className='product-dev-process'>
            <h2 className='web-dev-approach-heading'>Our marketing services</h2>
            <p className='marketing-services-subtitle'>From shaping your brand identity to crafting creative digital campaigns, we offer comprehensive marketing solutions to boost conversions, drive engagement, and increase revenue.</p>
        </div>

        <Container className='marketing-services-grid'>
            <Row className='d-flex justify-content-center'>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={SocialMediaMarketing} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Social Media Management</h3>
                    <p className='marketing-service-desc'>Manage social media profiles to grow followers.</p>
                </Col>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={EmailMarketing} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Email Marketing</h3>
                    <p className='marketing-service-desc'>Design campaigns to nurture leads and retain customers.</p>
                </Col>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={SEOIcon} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Search Engine Optimization</h3>
                    <p className='marketing-service-desc'>Improve website visibility to attract organic traffic.</p>
                </Col>

            </Row>

            <Row className='mt-5 d-flex justify-content-center'>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={Branding} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Branding</h3>
                    <p className='marketing-service-desc'>Develop a memorable brand identity.</p>
                </Col>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={PayPerClick} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Pay-Per-Click</h3>
                    <p className='marketing-service-desc'>Drive more conversions with highly-targeted ad campaigns.</p>
                </Col>

                <Col xs={4} className='marketing-service-col'>
                    <Image src={ContentMarketing} width={40} height={40} fluid />
                    <h3 className='marketing-service-title'>Content Marketing</h3>
                    <p className='marketing-service-desc'>Distribute valuable content to engage your audience.</p>
                </Col>

            </Row>            
        </Container>

        <div className='addvalue-container'>
        <div className='addvalue-content'>
          <h1 className='addvalue-title'>Services that <br />
            <span className='addvalue-usp'>add value</span> to your business</h1>

          <Row>
          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card1'>
              <h4 className='addvalue-card-title'>User Research</h4>
              <h2 className='addvalue-card-question'>Do your marketing strategies resonate with your target audience?</h2>
              <p className='addvalue-card-desc'>
              Our UX and user research services dive deep into understanding your audience's preferences and behaviors, providing data-driven insights to refine your marketing approach. Maximize your ROI by crafting campaigns that truly connect with your customers.
              </p>

              <div className='service-cta'>
                <a href="/ux-research">Explore our services for user research <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>

          <Col md={6} sm={12} className='mt-5'>
            <div className='addvalue-card addvalue-card2'>
              <h4 className='addvalue-card-title'>Website Development</h4>
              <h2 className='addvalue-card-question'>Is your current website delivering the user experience your customers expect?</h2>
              <p className='addvalue-card-desc'>
              Our web development solutions are designed to not only enhance your online
              presence but also boost your marketing efforts. With a responsive and 
              user-friendly website, you'll capture more leads, improve conversions, 
              and strengthen your brand's online impact.
              </p>

              <div className='service-cta'>
                <a href="/website">More about website development <ArrowRightCircle size={25} /></a>
              </div>
            </div>
          </Col>
          </Row>
        </div>

      </div>

    </motion.div>
  )
}

export default Marketing